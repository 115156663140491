import { publicRoutes } from './routes';
import type { IUserData } from '@lib/prefetchUserData';

const suspendBarReasons = ['end_of_trial', 'credit_expired_termination', 'debtor'] as const;
export type ISuspendBarReason = (typeof suspendBarReasons)[number];
export type ISuspendPageReason = 'fraud' | 'clients_request' | 'other';

export const isSuspendBarReason = (
  reason: ISuspendPageReason | ISuspendBarReason,
): reason is ISuspendBarReason => suspendBarReasons.includes(reason as ISuspendBarReason);

export const getAllRoutesWithAccess = ({
  user,
  enableStagingPages,
  withStorage,
}: {
  user: IUserData;
  enableStagingPages: boolean;
  withStorage?: boolean;
}) => {
  const isPayingCustomer =
    user.trial === undefined && user.account_info.suspension_reason !== 'end_of_trial';

  return {
    other: {
      home: { pathname: '/', access: user.isLoggedIn },
      ssl: {
        pathname: '/ssl-certificates',
        access: user.isLoggedIn && user.account_info.customer_slug !== 'cra-sub',
      },
      welcome: { pathname: '/welcome', access: user.isLoggedIn },
      onboarding: { pathname: '/set-up-your-account', access: user.isLoggedIn },
      verifyEmail: {
        pathname: '/verify-email',
        access: user.isLoggedIn && !user.account_info.email_confirmed,
      },
      requestAssistance: { pathname: '/request-assistance', access: user.isLoggedIn },
      exampleSampleLog: { pathname: '/others/example-of-raw-log-file.txt', access: true },
      accountDeactivated: {
        pathname: '/account-deactivated',
        access:
          user.account_info.suspension_reason !== undefined &&
          !isSuspendBarReason(user.account_info.suspension_reason),
      },
      setup2fa: publicRoutes.auth.setup2fa,
    },
    auth: {
      login: { pathname: '/auth/login', access: true },
    },
    support: publicRoutes.support,
    reports: {
      traffic: { pathname: '/reports/traffic', access: user.isLoggedIn },
      bandwidth: { pathname: '/reports/bandwidth', access: user.isLoggedIn },
      hitMiss: {
        pathname: '/reports/requests',
        access: user.isLoggedIn && user.account_info.customer_slug !== 'tiktok-livestreaming',
      },
      costs: {
        pathname: '/reports/costs',
        access:
          user.isLoggedIn &&
          isPayingCustomer &&
          user.account_info.customer_slug !== 'xvideos-main' &&
          user.account_info.customer_slug !== 'xvideos-sub' &&
          user.account_info.customer_slug !== 'cra-main' &&
          user.account_info.customer_slug !== 'cra-sub' &&
          user.billing !== undefined &&
          !user.billing.external_billing &&
          user.billing.current_pricing_type !== 'custom_plan',
      },
      headers: {
        pathname: '/reports/status-codes',
        access: user.isLoggedIn && user.account_info.customer_slug !== 'tiktok-livestreaming',
      },
      cacheStats: {
        pathname: '/reports/cache-stats',
        access: user.isLoggedIn && user.account_info.customer_slug === 'cra-main',
      },
      popularObjects: {
        pathname: '/reports/popular-objects',
        access: user.isLoggedIn && user.is_visible_popular_objects,
      },
      chartPerLocation: {
        pathname: '/reports/bandwidth-by-data-center',
        access:
          user.isLoggedIn &&
          (user.account_info.customer_slug === 'xvideos-main' ||
            user.account_info.customer_slug === 'xvideos-sub' ||
            enableStagingPages),
      },
      dailyOverview: {
        pathname: '/reports/daily-overview',
        access: user.isLoggedIn && user.is_visible_daily_overview,
      },
    },
    trafficLogs: {
      sampleLogs: {
        pathname: '/traffic-logs/sample-logs',
        access: user.isLoggedIn && user.account_info.customer_slug !== 'tiktok-livestreaming',
      },
      rawLogs: {
        pathname: '/traffic-logs/raw-logs',
        access: user.isLoggedIn && user.account_info.customer_slug !== 'cra-sub',
      },
    },
    cdn: {
      list: {
        pathname: '/cdn',
        access: user.isLoggedIn && user.account_info.customer_slug !== 'cra-sub',
      },
      create: {
        pathname: '/cdn/create',
        access:
          user.isLoggedIn &&
          !user.account_info.suspension_reason &&
          user.account_info.customer_slug !== 'cra-sub',
      },
      overview: {
        pathname: '/cdn/edit/:resourceId/overview',
        getRoute: (resourceId: string) => `/cdn/edit/${resourceId}/overview`,
        access: user.isLoggedIn && user.account_info.customer_slug !== 'cra-sub',
      },
      cnameAndSsl: {
        pathname: '/cdn/edit/:resourceId/cname-and-ssl',
        getRoute: (resourceId: string) => `/cdn/edit/${resourceId}/cname-and-ssl`,
        access: user.isLoggedIn && user.account_info.customer_slug !== 'cra-sub',
      },
      configuration: {
        pathname: '/cdn/edit/:resourceId/configuration',
        getRoute: (resourceId: string) => `/cdn/edit/${resourceId}/configuration`,
        access: user.isLoggedIn && user.account_info.customer_slug !== 'cra-sub',
      },
      accessProtection: {
        pathname: '/cdn/edit/:resourceId/access-protection',
        getRoute: (resourceId: string) => `/cdn/edit/${resourceId}/access-protection`,
        access: user.isLoggedIn && user.account_info.customer_slug !== 'cra-sub',
      },
      dataCenters: {
        pathname: '/cdn/edit/:resourceId/data-centers',
        getRoute: (resourceId: string) => `/cdn/edit/${resourceId}/data-centers`,
        access: user.isLoggedIn && user.account_info.customer_slug !== 'cra-sub',
      },
      purge: {
        pathname: '/cdn/edit/:resourceId/purge',
        getRoute: (resourceId: string) => `/cdn/edit/${resourceId}/purge`,
        access: user.isLoggedIn && user.account_info.customer_slug !== 'cra-sub',
      },
      purgeDetail: {
        pathname: '/cdn/edit/:resourceId/purge/:id',
        getRoute: (resourceId: string, purgeId: string) =>
          `/cdn/edit/${resourceId}/purge/${purgeId}`,
        access: user.isLoggedIn && user.account_info.customer_slug !== 'cra-sub',
      },
      prefetch: {
        pathname: '/cdn/edit/:resourceId/prefetch',
        getRoute: (resourceId: string) => `/cdn/edit/${resourceId}/prefetch`,
        access: user.isLoggedIn && user.account_info.customer_slug !== 'cra-sub',
      },
      prefetchDetail: {
        pathname: '/cdn/edit/:resourceId/prefetch/:id',
        getRoute: (resourceId: string, purgeId: string) =>
          `/cdn/edit/${resourceId}/prefetch/${purgeId}`,
        access: user.isLoggedIn && user.account_info.customer_slug !== 'cra-sub',
      },
      integration: {
        pathname: '/cdn/edit/:resourceId/integration',
        getRoute: (resourceId: string) => `/cdn/edit/${resourceId}/integration`,
        access: user.isLoggedIn && user.account_info.customer_slug !== 'cra-sub',
      },
    },
    stream: {
      list: {
        pathname: '/stream',
        access:
          user.isLoggedIn &&
          user.account_info.customer_slug !== 'cra-sub' &&
          user.account_info.has_stream_resources,
      },
      overview: {
        pathname: '/stream/edit/:resourceId/overview',
        getRoute: (resourceId: string) => `/stream/edit/${resourceId}/overview`,
        access:
          user.isLoggedIn &&
          user.account_info.customer_slug !== 'cra-sub' &&
          user.account_info.has_stream_resources,
      },
      cnameAndSsl: {
        pathname: '/stream/edit/:resourceId/cname-and-ssl',
        getRoute: (resourceId: string) => `/stream/edit/${resourceId}/cname-and-ssl`,
        access:
          user.isLoggedIn &&
          user.account_info.customer_slug !== 'cra-sub' &&
          user.account_info.has_stream_resources,
      },
      configuration: {
        pathname: '/stream/edit/:resourceId/configuration',
        getRoute: (resourceId: string) => `/stream/edit/${resourceId}/configuration`,
        access:
          user.isLoggedIn &&
          user.account_info.customer_slug !== 'cra-sub' &&
          user.account_info.has_stream_resources,
      },
      accessProtection: {
        pathname: '/stream/edit/:resourceId/access-protection',
        getRoute: (resourceId: string) => `/stream/edit/${resourceId}/access-protection`,
        access:
          user.isLoggedIn &&
          user.account_info.customer_slug !== 'cra-sub' &&
          user.account_info.has_stream_resources,
      },
      dataCenters: {
        pathname: '/stream/edit/:resourceId/data-centers',
        getRoute: (resourceId: string) => `/stream/edit/${resourceId}/data-centers`,
        access:
          user.isLoggedIn &&
          user.account_info.customer_slug !== 'cra-sub' &&
          user.account_info.has_stream_resources,
      },
      integration: {
        pathname: '/stream/edit/:resourceId/integration',
        getRoute: (resourceId: string) => `/stream/edit/${resourceId}/integration`,
        access:
          user.isLoggedIn &&
          user.account_info.customer_slug !== 'cra-sub' &&
          user.account_info.has_stream_resources,
      },
    },
    ddos: {
      index: {
        pathname: '/ddos-protection',
        access: user.isLoggedIn && user.configuration.tsunami_enabled,
      },
    },
    billing: {
      planManagement: {
        pathname: '/billing/plan-management',
        access:
          user.isLoggedIn &&
          isPayingCustomer &&
          user.billing !== undefined &&
          user.billing.current_pricing_type !== 'custom_plan' &&
          user.billing.future_pricing_type !== 'custom_plan' &&
          !user.account_info.customer_slug?.startsWith('tiktok') &&
          user.account_info.customer_slug !== 'xvideos-main' &&
          user.account_info.customer_slug !== 'xvideos-sub',
      },
      overview: {
        pathname: '/billing/overview',
        access: user.isLoggedIn && isPayingCustomer && user.billing !== undefined,
      },
      invoices: {
        pathname: '/billing/invoices',
        access: user.isLoggedIn && isPayingCustomer && user.billing !== undefined,
      },
      informations: {
        pathname: '/billing/details',
        access: user.isLoggedIn && isPayingCustomer && user.billing !== undefined,
      },
      addFunds: {
        pathname: '/billing/plan-management/add-credit',
        access:
          user.isLoggedIn &&
          isPayingCustomer &&
          user.billing !== undefined &&
          user.billing.current_pricing_type !== 'custom_plan' &&
          user.billing.future_pricing_type !== 'custom_plan',
      },
      monthlyPlan: {
        pathname: '/billing/plan-management/monthly-plan',
        access:
          user.isLoggedIn &&
          isPayingCustomer &&
          user.billing !== undefined &&
          user.billing.current_pricing_type !== 'custom_plan' &&
          user.billing.future_pricing_type !== 'custom_plan',
      },
      paymentMethod: {
        pathname: '/billing/payment-settings',
        access: user.isLoggedIn && isPayingCustomer && user.billing !== undefined,
      },
      customPlanUpgrade: {
        pathname: '/billing/upgrade-to-custom-plan',
        access:
          user.isLoggedIn &&
          user.billing?.future_pricing_type === 'custom_plan' &&
          !user.billing.external_billing,
      },
      customPlan: {
        pathname: '/billing/custom-plan/:planId',
        getRoute: (customPlanId: string) => `/billing/custom-plan/${customPlanId}`,
        access:
          user.isLoggedIn &&
          user.billing !== undefined &&
          (user.billing.current_pricing_type === 'custom_plan' || user.billing.external_billing),
      },
      customPlanAddCredit: {
        pathname: '/billing/add-credit',
        access:
          user.isLoggedIn &&
          user.billing !== undefined &&
          (user.billing.current_pricing_type === 'custom_plan' || user.billing.external_billing),
      },
      upgradeCrossroad: {
        pathname: '/billing/upgrade',
        access: user.isLoggedIn && !isPayingCustomer,
      },
      upgradeCredit: {
        pathname: '/billing/upgrade/credit',
        access: user.isLoggedIn && !isPayingCustomer,
      },
      upgradeMonthlyPlan: {
        pathname: '/billing/upgrade/monthly-plan',
        access: user.isLoggedIn && !isPayingCustomer,
      },
      upgradeCustomPlan: {
        pathname: '/billing/request-tailored-offer',
        access:
          user.isLoggedIn &&
          user.billing !== undefined &&
          user.billing.current_pricing_type !== 'custom_plan',
      },
    },
    settings: {
      profile: { pathname: '/account/profile', access: user.isLoggedIn },
      twoStepAuth: { pathname: '/account/two-step-authentication', access: user.isLoggedIn },
      api: { pathname: '/account/api', access: user.isLoggedIn },
    },
    customer: publicRoutes.customer,
    organization: {
      settings: {
        pathname: '/organization/settings',
        access: user.isLoggedIn && user.account_info.is_main_account,
      },
      teamMembers: {
        pathname: '/organization/team-members',
        access: user.isLoggedIn && user.account_info.is_main_account,
      },
    },
    origins: {
      storage: {
        pathname: '/origins/cdn77-storage',
        access:
          user.isLoggedIn &&
          ((user.account_info.customer_slug !== 'cra-sub' &&
            isPayingCustomer &&
            withStorage === true) ||
            user.is_cdn77_storage_enabled),
      },
      storageDetail: {
        pathname: '/origins/cdn77-storage/:originId',
        getRoute: (id: string) => `/origins/cdn77-storage/${id}`,
        access:
          user.isLoggedIn &&
          ((user.account_info.customer_slug !== 'cra-sub' &&
            isPayingCustomer &&
            withStorage === true) ||
            user.is_cdn77_storage_enabled),
      },
      awsOrigins: {
        pathname: '/origins/aws-storage',
        access: user.isLoggedIn && user.account_info.customer_slug !== 'cra-sub',
      },
      awsOriginDetail: {
        pathname: '/origins/aws-storage/:originId',
        getRoute: (id: string) => `/origins/aws-storage/${id}`,
        access: user.isLoggedIn && user.account_info.customer_slug !== 'cra-sub',
      },
      yourOrigins: {
        pathname: '/origins/your-origins',
        access: user.isLoggedIn && user.account_info.customer_slug !== 'cra-sub',
      },
      yourOriginDetail: {
        pathname: '/origins/your-origins/:originId',
        getRoute: (id: string) => `/origins/your-origins/${id}`,
        access: user.isLoggedIn && user.account_info.customer_slug !== 'cra-sub',
      },
      objectStorageOrigins: {
        pathname: '/origins/cdn77-object-storage',
        access: user.isLoggedIn && (user.configuration.object_storage_enabled || !isPayingCustomer),
      },
      objectStorageOriginDetail: {
        pathname: '/origins/cdn77-object-storage/:originId',
        getRoute: (id: string) => `/origins/cdn77-object-storage/${id}`,
        access: user.isLoggedIn && user.configuration.object_storage_enabled,
      },
      objectStorageAccessKeys: {
        pathname: '/origins/cdn77-object-storage/access-keys',
        access: user.isLoggedIn && user.configuration.object_storage_enabled,
      },
      objectStorageStorageReports: {
        pathname: '/origins/cdn77-object-storage/storage-reports',
        access: user.isLoggedIn && user.configuration.object_storage_enabled,
      },
    },
  };
};
